@font-face {
  font-family: 'PatrickHand';
  src: url('./fonts/PatrickHand.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Delius';
  src: url('./fonts/Delius.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --verdeescuro1: #285d56;
  --verdeescuro2: #2A4951;
  --verde1: #65b341;
  --verde2: #55a630;
  --branco: #f8f6f4;
  --brancoraiz: rgb(248, 246, 244);
  --laranja: #D35400;
  --vermelho: #d62828;
  --amarelo1: #ffba08;
  --amarelo2: #e09f3e;
  --fosco: #333;
  --rgb: rgba(255, 255, 255, 0.1);
}

* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow-x: hidden;
  /* -ms-overflow-style: none; */
  /* scrollbar-width: none; */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body::-webkit-scrollbar {
  display: none;
}

/* página inicial */

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.detalhes_produto_div {
  margin-top: 50px;
  background: rgb(255, 255, 255);
  padding: 50px;
  border-radius: 10px;
  margin-bottom: 50px
}

.detalhes_produto_div2 {
  display: flex;
  flex-direction: row
}

.detalhes_produto_div3 {
  display: flex;
  flex-direction: column;
  margin-left: 50px
}

.detalhes_produto_img {
  width: 400px;
  border-radius: 20px
}

.detalhes_produto_icone {
  font-size: 400px
}

.home_itens_menu_mobile {
  margin: 15px 0;
  color: #121212;
  font-weight: 900;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s;
}

.home_modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000
}

.home_btn_anunciante {
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-direction: row;
  background: var(--verde1);
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
}

.home_btn_anunciante:hover {
  background: var(--verde2);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: black;
}

.home_modal_erro {
  position: relative;
  background-color: var(--vermelho);
  padding: 20px;
  border-radius: 20px;
  width: 40%;
  min-height: 250px;
  text-align: center;
  animation: fadeIn 0.3s ease-out;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home_modal_regiao {
  position: relative;
  background-color: var(--verdeescuro1);
  padding: 20px;
  border-radius: 20px;
  width: 50%;
  min-height: 350px;
  max-height: 80%;
  text-align: center;
  animation: fadeIn 0.3s ease-out;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home_modal_regiao h2 {
  font-family: 'PatrickHand';
  font-size: 30px;
  color: #fff;
}

.home_modal_regiao img {
  width: 150px;
  height: auto;
  margin-top: 25px;
}

.home_modal_regiao_div {
  width: 55%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.home_icone_regiao {
  font-size: 80px;
  color: #000;
}

.home_header_div {
  position: relative;
  background-color: var(--verdeescuro1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row
}

.home_header_div_responsivo {
  display: none;
}

.home_header_div img {
  height: 50px;
  padding: 20px
}

.home_header_regiao {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 10px;
}

.home_header_btn:hover {
  color: #dccece;
}

.home_header_regiao:hover {
  background-color: var(--rgb);
}

.home_header_conta {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  padding: 30px;
  border-radius: 10px;
}

.home_header_conta:hover {
  background-color: var(--rgb);
}

.home_header_btn {
  padding: 35px;
  margin-top: 0px;
  margin-bottom: 0px;
  min-width: 120px;
  background-color: var(--verdeescuro1);
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  border-radius: 10px;
}

.home_div_contato {
  position: relative;
  left: 20%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.home_div_contato span {
  color: #fff;
  font-size: 20px;
  margin-left: 10px;
}

.home_div_titulo {
  position: relative;
  left: 20%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.home_div_lojas {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 100px;
}

.home_div_lojas h1 {
  color: #fff;
  font-size: 35px;
}

.home_div_lojas h2 {
  color: #fff;
  font-size: 21px;
  width: 50%;
  margin-top: 10px;
}

.home_div_titulo h1 {
  color: #fff;
  font-size: 35px;
}

.home_div_titulo h2 {
  color: #fff;
  font-size: 21px;
  width: 50%;
  margin-top: 10px;
}

.home_swipe-container {
  position: relative;
  width: 100%;
  height: 370px;
  overflow: hidden;
}

.home_swipe-page {
  display: flex;
  width: 300%;
  /* Cada página ocupa 100% da largura */
  height: 100%;
  transition: transform 0.5s ease;
}

.home_swipe-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

.home_swipe-controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 10px;
}

.home_arrow-left {
  position: absolute;
  bottom: 45%;
  left: 10%;
  font-size: 50px;
  color: #fff;
  cursor: pointer;
  opacity: 0.7;
}

.home_arrow-right {
  position: absolute;
  bottom: 45%;
  right: 10%;
  font-size: 50px;
  color: #fff;
  cursor: pointer;
  opacity: 0.7;
}

.home_arrow-left-produtos {
  font-size: 50px;
  padding: 10px;
  align-self: center;
  color: var(--verde1);
  cursor: pointer;
  opacity: 0.7;
}

.home_arrow-right-produtos {
  font-size: 50px;
  padding: 10px;
  align-self: center;
  color: var(--verde1);
  cursor: pointer;
  opacity: 0.7;
}

.home_vermaisprodutos {
  display: flex;
  justify-content: center;
}

.home_vermaisprodutos button {
  font-size: 17px;
  padding: 12px;
  outline: none;
  border: 1px solid black;
  background: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 15px;
}

.home_vermaisprodutos button:hover {
  background-color: var(--verde1);
  color: #fff;
  border-color: #fff;
}

.home_acessarloja {
  display: flex;
  flex-direction: row;
}

.home_acessarloja a {
  font-size: 17px;
  padding: 12px;
  outline: none;
  border: 1px solid black;
  background: none;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
}

.home_acessarloja a:hover {
  background-color: var(--verde1);
  color: #fff;
  border-color: #fff;
}

.home_criarloja {
  display: flex;
  justify-content: center;
}

.home_criarloja button {
  font-size: 17px;
  padding: 12px;
  outline: none;
  background: var(--verde1);
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
  border: none;
  color: #fff
}

.home_criarloja button:hover {
  background-color: var(--verde2);
}

.home_lojas_div {
  display: flex;
  flex-direction: row;
}

.home_lojas_iconeforn {
  width: 100px;
  border-radius: 10px;
}

.home_lojas_nomeforn {
  font-size: 17px;
  color: #000;
  text-align: center;
  margin-top: 10px;
}

.home_lojas_divloja {
  padding: 20px;
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  margin: 20px;
  cursor: pointer;
}

.home_lojas_divloja:hover {
  background-color: var(--rgb);
}

.home_indicators {
  display: flex;
  gap: 10px;
}

.home_indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #fff;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.3s;
}

.home_indicator.active {
  opacity: 1;
}

.home_produtos {
  padding: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: var(--brancoraiz)
}

.home_anuncios {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px;
  background-color: var(--brancoraiz);
  min-height: 800px;
}

.home_anuncios_categorias_responsivo {
  display: none;
  margin-top: 15px;
  margin-bottom: 15px;
  justify-content: center;
}

.home_anuncios_categorias {
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.home_anuncios_categorias h3 {
  font-size: 18px;
  margin-left: 8px;
  margin-bottom: 10px;
}

.home_anuncios_categorias span {
  font-size: 18px;
  padding: 8px;
  cursor: pointer;
}

.home_anuncios_categoria_selecionada {
  color: var(--verde1);
}

.home_anuncios_produtos {
  display: flex;
  flex: 1;
  margin-left: 30px;
  padding: 20px;
  justify-content: center
}

/* fim página inicial */

.naoexiste {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #88D475;
  width: 100hw;
  height: 100vh;
}

.naoexiste_img {
  max-height: 60vh;
  max-width: 60hw
}

.naoexiste_h1 {
  color: #333;
  font-family: 'PatrickHand';
  font-size: 35px;
  text-align: center;
  margin-top: 20px;
  font-weight: 900;
}

.guandu_rounds_div {
  display: flex;
  width: 20%;
  flex-direction: column;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 20px;
  background-color: var(--rgb);
  height: 350px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 20px;
}

.guandu_quem {
  background-color: var(--verdeescuro1);
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 150px;
  padding-right: 150px;
}

.guandu_quem2 {
  display: flex;
  flex-direction: row;
}

.guandu_quem2 img {
  width: 800px;
}

.guandu_quem h2 {
  text-transform: uppercase;
  font-family: 'PatrickHand';
  font-size: 50px;
  color: #fff;
  margin-bottom: 20px;
}

.guandu_quem h3 {
  font-family: 'Delius';
  font-size: 18px;
  color: #fff;
  margin-right: 20px;
}

.guandu_como {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #334C46;
}

.guandu_como img {
  width: 130px;
}

.guandu_como h2 {
  margin-left: 30px;
  font-family: 'PatrickHand';
  font-size: 50px;
  text-transform: uppercase;
  color: #fff;
  width: 30%;
}

.guandu_rounds_div h2 {
  font-size: 35px;
  font-family: 'Patrickhand';
  align-self: center;
  color: #fff;
  text-transform: uppercase;
}

.guandu_rounds_div h3 {
  font-size: 23px;
  font-family: 'Patrickhand';
  text-align: center;
  color: #fff;
}

.guandu_produtores {
  padding: 30px;
  background-color: var(--verdeescuro1);
}

.guandu_produtores2 {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.guandu_produtores h2 {
  font-family: 'PatrickHand';
  color: #fff;
  font-size: 60px;
  margin-bottom: 40px;
  text-transform: uppercase;
}

.guandu_produtores3 {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.guandu_produtores3 h3 {
  font-family: 'PatrickHand';
  color: #fff;
  font-size: 30px;
  text-transform: uppercase;
  text-align: center;
}

.guandu_logo {
  width: 270px
}

.guandu_footer {
  background-color: #d4f87d;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.guandu_footer2 {
  display: flex;
  flex-direction: row;
  margin-top: 40px
}

.guandu_footer h2 {
  font-family: 'PatrickHand';
  color: #016301;
  font-size: 40px;
  text-transform: uppercase;
}

.guandu_container1 {
  flex-direction: row;
  background-color: var(--verde2);
  padding-top: 35px;
  padding-bottom: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
}

.guandu_div1 {
  display: flex;
  width: 80%;
  flex-direction: column;
}

.guandu_div1 h3 {
  color: #A5E800;
  font-weight: 700;
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 20px;
}

.guandu_div2 {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.guandu_div2 h2 {
  color: #fff;
  font-weight: 700;
  font-size: 19px;
  text-align: center;
  text-transform: uppercase;
  margin-left: 50px;
  margin-top: 5px;
}

.guandu_button {
  cursor: pointer;
  padding: 20px;
  text-transform: uppercase;
  border-radius: 20px;
  border: none;
  color: #fff;
  background-color: var(--verdeescuro1);
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}

.guandu_button:hover {
  background-color: var(--verdeescuro2);
}

.guandu_compre {
  background-color: #F2F0C6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.guandu_compre h2 {
  font-size: 40px;
  padding: 10px;
  font-weight: 900;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: var(--verdeescuro1);
  text-align: center;
  text-transform: uppercase;
}

.guandu_container2 {
  flex-direction: column;
  padding-top: 35px;
  padding-bottom: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
}

.guandu_div3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.guandu_div3 h3 {
  color: #333;
  width: 50%;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  margin-left: 20px;
}

.guandu_div4 {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.guandu_div4 h3 {
  color: #333;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  margin-left: 20px;
}

.termos1 {
  background-color: #e19605;
  padding: 35px
}

.termos1 h1 {
  font-size: 22px;
  text-align: center;
  color: #fff;
}

.termos2 {
  background-color: #f0ba50;
  padding: 20px;
}

.termos2 h2 {
  font-size: 18px;
  padding: 20px;
}

.termos2 h3 {
  background-color: #f0ba50;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 17px;
  padding-bottom: 10px;
  font-family: 'Delius';
  color: #000;
  margin: 0px;
}

.link_img {
  width: 70px;
  height: 70px;
}

.link_h1 {
  margin-bottom: 10px;
  font-size: 40px;
  font-family: 'PatrickHand';
  color: #fff;
}

.link_h2 {
  color: #fff;
  font-family: 'Delius';
  font-size: 18px;
}

.link_bloco {
  border-radius: 15px;
  background-color: #23231A;
  width: 40%;
  height: 75px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 10px;
  text-decoration: none;
}

.link_bloco:hover {
  background-color: #444;
}

.fundoescuro {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.fundoescuro2 {
  /* é usado acima do fundoescuro em loads */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.header {
  padding: 40px;
  position: relative;
  background-color: var(--verdeescuro1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row
}

.seedling {
  color: var(--verde1);
  font-size: 50px
}

.phone {
  color: var(--verde1);
  margin-left: 15px;
  font-size: 30px
}

.produtor_titulo {
  font-size: 40px;
  padding: 10px;
  font-family: 'PatrickHand';
  color: var(--verdeescuro1);
  text-align: center;
}

.botoes_header {
  position: absolute;
  right: 30px;
  flex-direction: row;
  display: flex
}

.contato {
  font-size: 20px;
  text-align: 'center';
  font-family: 'Delius';
  color: #fff;
  margin-left: 10px
}

.descricaoforn {
  font-size: 21px;
  font-family: 'Delius';
  color: var(--branco);
  margin-top: 25px;
  text-align: center
}

.produtor_nome {
  font-family: 'Delius';
  font-weight: 900;
  font-size: 20px;
  color: var(--verde1);
  padding: 10px;
  margin-top: 5px;
  cursor: pointer
}

.promocao_titulo {
  color: var(--verde1);
  font-size: 25px;
  margin-top: 30px;
  font-weight: 700
}

.modal_conta_entrar_criar {
  font-size: 22px;
  font-family: 'PatrickHand';
  color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  background-color: var(--rgb);
  cursor: pointer;
  width: 250px;
  text-align: center;
}

.modal_conta_entrar_criar:hover {
  color: #999;
}

.modal_conta_inputs {
  border: none;
  border-radius: 10px;
  padding: 15px;
  background: var(--rgb);
  color: var(--branco);
  font-size: 18px;
  font-weight: bold;
  outline: none;
  font-family: 'Delius';
  margin-top: 5px;
  margin-bottom: 20px;
}

.modal_conta_inputs::placeholder {
  color: #f0ffff94;
}

.modal_conta_endereco0 {
  border: none;
  border-radius: 10px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.05);
  color: var(--branco);
  font-size: 18px;
  font-weight: bold;
  outline: none;
  font-family: 'Delius';
}

.filtro_input {
  border: none;
  border-radius: 10px;
  padding: 15px;
  color: var(--branco);
  font-size: 18px;
  font-weight: bold;
  outline: none;
  font-family: 'Delius';
  background-color: var(--fosco);
  margin-left: 20px;
}

.filtro_bloco {
  padding: 25px;
  background-color: var(--fosco);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row
}

.menu_icone {
  font-size: 25px;
  color: #fff;
  padding: 10px;
  border-radius: 15px;
  background: var(--verde1);
  cursor: pointer
}

.filtro_input::placeholder {
  color: #f0ffff94;
}

.btncontinuar_load {
  border: 5px solid transparent;
  border-top: 5px solid orange;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  align-self: center;
  margin: 10px;
}

.load_inicial {
  border: 7px solid transparent;
  border-top: 7px solid var(--verde1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  align-self: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.iconex {
  position: absolute;
  top: 0;
  right: 0;
  height: 27px;
  cursor: pointer;
  padding: 20px;
  color: #fff;
  font-size: 30px;
}

.iconeback {
  position: absolute;
  top: 0;
  left: 0;
  height: 27px;
  cursor: pointer;
  padding: 20px;
  color: #fff;
  font-size: 30px;
}

.modalconta {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: var(--verdeescuro1);
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 50%;
  min-height: 350px;
  max-height: 80%;
}

.modalconta_div {
  width: 55%;
  display: flex;
  flex-direction: column
}

.modalconta_div_end0 {
  width: 55%;
  display: flex;
  flex-direction: column
}

.continuar_compra_valores {
  display: flex;
  width: 60%;
  margin-top: 5px;
  flex-direction: row;
  justify-content: space-between
}

.btncontinuar {
  text-align: center;
  padding: 16px;
  border-radius: 10px;
  font-weight: 600;
  background: var(--verde1);
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.btncidades {
  padding: 16px 20px;
  border-radius: 10px;
  font-weight: 600;
  background: #4A7C59;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.modal_conta_titulos {
  color: #fff;
  font-size: 17px;
  font-family: 'Delius'
}

.dropdown_cidades {
  border-radius: 15px;
  background-color: #f1f1f1;
  overflow-y: hidden;
}

.dropdown_scroll {
  max-height: 150px;
  overflow-y: hidden;
}

.dropdown_texto {
  display: block;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 30px;
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.dropdown_texto:hover {
  background-color: #e0e4dc;
}

.scroll {
  overflow-y: auto;
  margin-top: 40px;
  width: 100%;
}

.scroll::-webkit-scrollbar {
  width: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

.card_produto {
  width: 210px;
  padding: 10px;
  margin: 10px;
  background-color: #fff;
  border-radius: 15px;
  text-align: center;
  height: 325px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer
}

.card_produto_img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 15px
}

.card_produto_titulo {
  font-family: 'Delius';
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cardPedido {
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  margin-right: 10px;
}

.cardPedido:hover {
  background-color: var(--rgb);
}

.info_titulo_perfil {
  font-family: 'Delius';
  font-size: 17px;
  text-align: center;
  padding: 23px;
  align-self: center
}

.botaoFlutuante {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #23231A;
  border: none;
  border-radius: 30%;
  cursor: pointer;
}

.botaoFlutuante:hover {
  background-color: #333;
}

.largura_filtros {
  width: 20%;
  padding-left: 30px
}

.filtros_titulo {
  font-size: 20px;
  font-family: 'Delius';
  display: block;
  margin-bottom: 10px;
  margin-top: 30px;
}

.filtros_text {
  font-size: 17px;
  font-family: 'Delius';
  cursor: pointer;
  padding: 5px;
  color: #000;
}

.filtros_text_select {
  font-size: 24px;
  font-family: 'Delius';
  cursor: pointer;
  padding: 5px;
  color: var(--verde1)
}

.modalproduto {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: var(--branco);
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 50%;
  min-height: 350px;
  max-height: 400px;
}

.modalproduto2 {
  display: flex;
  flex-direction: row;
  width: 100%
}

.modalproduto2_icone {
  width: 40%
}

.modalproduto2_infos {
  width: 60%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column
}

.modalproduto_titulo {
  font-size: 22px;
  font-family: 'Delius';
  font-weight: 600
}

.modalproduto_tamanho {
  font-size: 17px;
  font-family: 'Delius';
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 5px;
}

.modalproduto_estoque {
  font-size: 17px;
  margin-top: 10px;
  font-weight: 600
}

.modalproduto_descricao {
  margin-top: 20px;
  font-size: 19px
}

.select {
  display: block;
  padding: 10px;
  color: var(--laranja);
  background-color: var(--branco);
  border: none;
  border-radius: 10px;
  font-size: 15px;
  font-family: 'Delius';
  font-weight: 800;
}

.quantidade_container {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_quantidade {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.btn_fechado {
  border: none;
  border-radius: 12px;
  font-size: 18px;
  padding: 13px;
  margin-top: 15px;
  font-family: 'Delius';
  font-weight: 600;
  background-color: orange;
  color: #fff;
  width: 70%;
  text-align: center;
}

.btn_adicionar {
  border: none;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  width: 70%;
  padding: 13px;
  margin-top: 15px;
  font-family: 'Delius';
  font-weight: 600;
  background-color: var(--verde1);
  color: #fff;
}

.btn_adicionar:hover {
  background-color: var(--verde2);
}

.carrinho {
  position: fixed;
  top: 0;
  right: -385px;
  /* Inicialmente escondido à direita */
  width: 385px;
  height: 100%;
  background-color: var(--branco);
  transition: right 0.25s ease-in-out;
  overflow-x: hidden;
  overflow-y: hidden;
}

.carrinho-aberto {
  right: 0
}

.carrinho_flutuante {
  position: fixed;
  bottom: 20px;
  width: 40%;
  left: 50%;
  transform: translateX(-50%);
}

.carrinho_flutuante_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  border: none;
  border-radius: 15px;
  width: 100%;
  color: #fff;
  padding: 15px;
  font-size: 17px;
  font-family: 'Delius';
  font-weight: 700;
  cursor: pointer;
}

.carrinho_flutuante_qtd {
  background-color: var(--verde1);
  padding: 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 16px;
  text-align: center;
  color: #fff;
  font-weight: 800;
}

.carrinho_flutuante_text {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.carrinho_area_fechar {
  background-color: var(--verdeescuro1);
  display: flex;
  align-items: center;
  height: 10%;
}

.carrinho_iconex {
  position: absolute;
  top: 0;
  left: 0;
  height: 27px;
  cursor: pointer;
  padding: 20px;
  color: #fff;
  font-size: 30px;
}

.carrinho_footer {
  position: absolute;
  bottom: 0;
  height: 15%;
  display: flex;
  width: 100%;
  background-color: var(--verdeescuro1);
  align-items: center;
}

.carrinho_footer_preco {
  font-size: 20px;
  font-family: 'Delius';
  font-weight: 800;
  margin-left: 20px;
  color: #fff;
}

.carrinho_footer_btn {
  border: none;
  background-color: var(--verde1);
  font-weight: 800;
  border-radius: 16px;
  font-size: 18px;
  font-family: 'Delius';
  color: #fff;
  padding: 15px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.carrinho_footer_btn:hover {
  background-color: var(--verde2);
}

.opcao_continuar_conpra {
  padding: 10px;
  width: 150px;
  height: 150px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.opcao_continuar_conpra:hover {
  background: var(--rgb);
}

.opcao_continuar_conpra_select {
  padding: 10px;
  width: 150px;
  height: 150px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  background: var(--rgb);
}

.icone_continuar_compra_pagamento {
  font-size: 55px;
}

.card_cartao_continuar_compra {
  border-radius: 10px;
  padding: 10px
}

.card_cartao_continuar_compra:hover {
  background: var(--rgb);
}

.card_cartao_continuar_compra_select {
  border-radius: 10px;
  padding: 10px;
  background-color: var(--rgb);
}

.opcao_continuar_conpra_texto {
  font-size: 18px;
  font-family: 'Delius';
  margin-top: 10px;
  color: #fff;
  text-align: center;
}

.cupom_input {
  border: none;
  border-radius: 10px;
  padding: 15px;
  background: var(--rgb);
  color: var(--branco);
  font-size: 16px;
  font-weight: 900;
  outline: none;
}

.cupom_input::placeholder {
  color: #f0ffff94;
}


/* pagina inicial app */

.footer1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center
}

.footer2 {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.modelo2_capa {
  height: 25%;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  align-self: center
}

.modelo2_header {
  display: flex;
  flex-direction: row;
  max-width: 50%;
  top: -5%;
  position: relative;
  margin-left: auto;
  margin-right: auto
}

.modelo2_search {
  display: flex;
  flex-direction: row;
  max-width: 30%;
  padding: 15px;
  background: var(--branco);
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  top: -2%;
  position: relative;
}

.modelo2_search_input {
  border: none;
  padding: 5px;
  color: #000;
  background-color: var(--branco);
  display: flex;
  flex: 1;
  font-size: 18px;
  font-weight: bold;
  outline: none;
  font-family: 'Delius';
  margin-left: 20px;
}

.modelo2_forn {
  cursor: pointer;
  flex: 3;
  flex-direction: row;
  display: flex;
  margin-right: 5px;
  padding: 15px;
  background: var(--branco);
  border-radius: 15px
}

.modelo2_forn_img {
  width: 80px;
  height: 80px;
  border-radius: 10px
}

.modelo2_forn_text {
  font-size: 24px;
  font-family: 'PatrickHand';
  color: '#000';
  padding: 20px
}

.modelo2_card_produto {
  width: 50%;
  padding: 10px;
  background-color: var(--branco);
  border-radius: 15px;
  cursor: pointer
}

.modelo2_card_produto_img {
  width: 120px;
  object-fit: cover;
  border-radius: 15px;
  margin: 5px;
}

.modelo2_card_produto_noimg {
  font-size: 120px
}

.recuperacao_form {
  display: flex;
  flex-direction: column;
  padding: 35px;
  border-radius: 10px;
  width: 300px;
  background-color: var(--verdeescuro1);
}

.recuperacao_form input {
  margin-bottom: 15px;
  border: none;
  border-radius: 10px;
  padding: 15px;
  background: var(--rgb);
  color: #fff;
  font-size: 18px;
  outline: none;
}

.recuperacao_form input::placeholder {
  color: #ccc;
}

.recuperacao_form button {
  padding: 15px;
  background-color: var(--verde1);
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.recuperacao_form button:hover {
  background-color: var(--verde2);
}

.load_recuperacao {
  border: 7px solid transparent;
  border-top: 7px solid var(--verdeescuro1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  align-self: center;
}

.termos_footer {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center
}

@media screen and (max-width: 1300px) {

  .home_header_btn {
    padding: 10px;
  }
}

@media screen and (max-width: 900px) {

  .detalhes_produto_div {
    margin-top: 20px;
    padding: 15px;
  }

  .detalhes_produto_div2 {
    flex-direction: column
  }

  .detalhes_produto_div3 {
    margin-left: 0px;
    margin-top: 20px;
    align-items: center;
  }

  .detalhes_produto_img {
    width: 250px;
    align-self: center;
  }

  .detalhes_produto_icone {
    font-size: 250px;
    align-self: center;
  }

  .home_lojas_div {
    flex-direction: column;
  }

  .home_div_lojas h1 {
    font-size: 28px;
    text-align: center;
  }

  .home_anuncios_categorias {
    padding: 0px;
  }

  .home_anuncios_categorias_normal {
    display: none;
  }

  .home_anuncios_categorias_responsivo {
    display: flex;
  }

  .home_anuncios {
    padding: 0px;
  }

  .home_anuncios_produtos {
    padding: 0px;
    margin-left: 0px;
  }

  .home_produtos {
    padding: 10px
  }

  .home_div_contato span {
    font-size: 18px;
  }

  .home_div_titulo h1 {
    font-size: 28px;
  }

  .home_div_titulo h2 {
    font-size: 18px;
    width: 60%;
  }

  .home_arrow-left {
    font-size: 30px;
  }

  .home_arrow-right {
    font-size: 30px;
  }

  .home_indicator {
    width: 10px;
    height: 10px;
  }

  .home_modal_regiao {
    width: 80%;
  }

  .home_modal_regiao h2 {
    font-size: 25px;
  }

  .home_modal_regiao_div {
    width: 100%;
  }

  .home_header_regiao {
    margin-left: 0px;
    padding-top: 10px;
  }

  .home_header_div {
    display: none;
  }

  .home_header_div_responsivo {
    background-color: var(--verdeescuro1);
    display: block;
  }

  .modelo2_capa {
    height: auto;
    align-items: baseline;
  }

  .modelo2_header {
    max-width: 90%;
  }

  .modelo2_forn {
    flex: 1
  }

  .modelo2_forn_img {
    width: 50px;
    height: 50px;
  }

  .modelo2_forn_text {
    font-size: 20px;
    padding: 10px;
  }

  .modelo2_card_produto {
    width: 90%;
  }

  .modelo2_card_produto_img {
    width: 100px;
    border-radius: 10px
  }

  .modelo2_card_produto_noimg {
    font-size: 100px
  }

  .opcao_continuar_conpra {
    width: 110px;
    height: 110px;
  }

  .opcao_continuar_conpra_select {
    width: 110px;
    height: 110px;
  }

  .icone_continuar_compra_pagamento {
    font-size: 40px;
  }

  .naoexiste_img {
    padding-right: 50px;
    padding-left: 50px;
  }

  .naoexiste_h1 {
    font-size: 28px;
  }

  .guandu_container1 {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
  }

  .guandu_div2 h2 {
    margin-left: 0px;
    margin-top: 5px;
  }

  .guandu_container2 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .guandu_div3,
  .guandu_div4 {
    flex-direction: column;
  }

  .guandu_div3 h3,
  .guandu_div4 h3 {
    margin-left: 0px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .guandu_quem {
    padding-left: 20px;
    padding-right: 20px;
  }

  .guandu_quem2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .guandu_quem2 img {
    width: 180px;
  }

  .guandu_rounds_div {
    width: 100%;
    margin-bottom: 30px;
  }


  .guandu_como {
    flex-direction: column;
  }

  .guandu_como img {
    width: 100px;
  }

  .guandu_como h2 {
    font-size: 35px;
    margin-left: 0px;
    text-align: center;
    width: 100%;
    margin-top: 20px;
  }

  .guandu_footer {
    flex-direction: column;
    padding: 20px;
  }

  .guandu_footer2 {
    flex-direction: column;
  }

  .guandu_footer h2 {
    font-size: 35px;
  }

  .link_bloco {
    width: 93%;
  }

  .titulo_pagina_inicial {
    font-size: 60px;
  }

  .footer1 {
    flex-direction: column;
  }

  .footer2 {
    margin-bottom: 20px;
  }

  .termos_footer {
    flex-direction: column;
  }

  .termos_footer_privacidade {
    margin-top: 20px;
  }

  .header {
    flex-direction: column;
  }

  .botoes_header {
    position: relative;
    right: 0px;
  }

  .largura_filtros {
    width: 35%;
  }

  .card_produto {
    width: 160px;
    height: 300px;
    margin: 5px;
  }

  .card_produto_img {
    width: 100%;
    height: 150px;
  }

  .card_produto_titulo {
    font-size: 15px;
    margin-top: 5px;
  }

  .modalproduto {
    width: 80%;
    max-height: 80%;
  }

  .modalproduto2 {
    flex-direction: column;
  }

  .modalproduto2_icone {
    width: 50%;
    align-self: center;
  }

  .modalproduto2_infos {
    width: 100%
  }

  .btn_fechado {
    width: 100%
  }

  .btn_adicionar {
    width: 100%
  }

  .modalproduto_titulo {
    font-size: 20px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .modalconta {
    width: 80%;
  }

  .modalconta_div {
    width: 100%;
  }

  .modalconta_div_end0 {
    width: 100%;
    margin-top: 45px;
  }

  .continuar_compra_valores {
    width: 100%;
  }

  .contato {
    font-size: 16px;
  }

  .descricaoforn {
    font-size: 19px;
  }

  .modalproduto_descricao {
    font-size: 16px
  }

  .carrinho {
    right: -300px;
    width: 300px;
  }

  .carrinho-aberto {
    right: 0px;
  }

  .carrinho_flutuante {
    width: 90%;
  }
}